import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Riptide } from './Riptide.component';
import {
    MarkdownRemarkConnection,
    MarkdownRemarkEdge
} from '../../generated/graphql-types';

interface IRiptideRenderProps {
    articles: MarkdownRemarkConnection;
}

function mapArticleNodeToArticleSummary(
    articleNodeEdge: MarkdownRemarkEdge
): wmw.IContentSummary {
    const articleNode = articleNodeEdge.node;

    const {
        title,
        summary,
        type,
        previewLightImage,
        previewIllustrationOutlineShape,
        imageAlt
    } = articleNode.frontmatter;

    const slug = articleNode.fields.slug;

    const articleSummary: wmw.IContentSummary = {
        title,
        summary,
        type,
        slug,
        highlightColor: 'electricBlue'
    };

    if (previewLightImage && previewLightImage.publicURL) {
        articleSummary.image = {
            alt: imageAlt,
            shapeOutline:
                previewIllustrationOutlineShape &&
                previewIllustrationOutlineShape.publicURL,
            src: previewLightImage && previewLightImage.publicURL
        };
    }

    return articleSummary;
}

export const RiptideContainer = () => (
    <StaticQuery
        query={graphql`
            query ArticlesQuery {
                articles: allMarkdownRemark(
                    filter: {
                        frontmatter: { draft: { ne: true } }
                        fileAbsolutePath: { regex: "/articles/" }
                    }
                    sort: { fields: frontmatter___order, order: ASC }
                ) {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                summary
                                type
                                imageAlt
                                previewIllustrationOutlineShape {
                                    publicURL
                                }
                                previewLightImage {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ articles }: IRiptideRenderProps) => {
            const articleSummaries = articles.edges.map(
                mapArticleNodeToArticleSummary
            );
            return <Riptide articles={articleSummaries} />;
        }}
    />
);
