import React from 'react';
import styled from '../../styled-components';
import { Link, Swell, StylelessLink, Constrain, HeadingLevel1 } from '../ui';
import { pixelToRem, media } from '../../utilities';

interface ISwellWrapperProps extends wmw.IBaseStyledComponentProps {
    title: string;
    subtitle: string;
    sectionId?: string;
}

const isOdd = (num: number) => num % 2;

const FancyTitle = styled.span`
    display: inline-block;
    transform: rotate(-7deg);
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level3.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level2.lineHeight};
    font-weight: normal;
    color: ${({ theme }) => theme.colors.electricBlue.color};
    margin-right: 0.5rem;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level2.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level1.lineHeight};
    `}
`;

const SummaryWrapper = styled.div`
    background: ${({ theme }) => theme.colors.deepBlue.color};
    color: ${({ theme }) => theme.colors.white.color};
    padding: 4.5rem 0;
    overflow: auto;

    ${media.xl`
        padding: 9rem 0;
    `}

    ${Link} {
        color: ${({ theme }) => theme.colors.white.color};

        &:before {
            background: ${({ theme }) => theme.colors.white.color};
        }
    }

    ${StylelessLink} {
        color: ${({ theme }) => theme.colors.white.color};
    }

`;

const Heading = styled(HeadingLevel1)`
    margin-bottom: 6rem;

    ${media.m`
        margin-bottom: 7rem;
    `}
`;

const SwellTop = styled(Swell)`
    margin-bottom: 0.7rem;
    fill: ${({ theme }) => theme.colors.deepBlue.color};
`;

export const SwellWrapper: React.FunctionComponent<ISwellWrapperProps> = ({ title, subtitle, sectionId, children }) => (
    <>
        <SwellTop id={sectionId} />
        <SummaryWrapper>
            <Constrain>
                <Heading>
                    <FancyTitle>{title}</FancyTitle> {subtitle}
                </Heading>
                {children}
            </Constrain>
        </SummaryWrapper>
    </>
);
