import React from 'react';
import styled, { css } from '../../styled-components';
import { LineTitle } from '../LineTitle/LineTitle.component';
import { HeadingLink, pullBackExtraWide, pullBackWide } from '../ui';
import { media, pixelToRem } from '../../utilities';

type ICaseStudySummaryProps = wmw.ICaseStudySummary &
    wmw.IBaseStyledComponentProps & {
        reverse?: boolean;
    };

const Name = LineTitle.withComponent('h4');

const Heading = styled.h3`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level3.lineHeight};
    font-weight: 500;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level2.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level2.lineHeight};
    `}
`;

export const TextWrapper = styled.div`
    ${Heading} {
        margin-bottom: ${pixelToRem(10)};
    }

    font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level5.lineHeight};

    ${media.xl`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
        `}
`;

export const ImageWrapper = styled.div`
    position: relative;

    @supports (display: flex) {
        /* don't add for IE */
        svg {
            height: 100%;
            width: 100%;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 20%;
        bottom: 20%;
        left: -100vw;
        right: -100vw;
        background-color: ${({ theme }) => theme.colors.orange.color};
        z-index: -1;
    }

    ${media.m`
        margin-bottom: 0;
        padding-right: 2rem;

        &:before {
            right: 0;
        }
    `}
`;

const UnstyledCaseStudySummary: React.FC<ICaseStudySummaryProps> = ({
    featuredLabel,
    title,
    supporting,
    Illustration,
    slug,
    className
}) => (
    <div className={className} data-testid="caseStudySummary">
        <ImageWrapper data-testid="caseStudySummaryImage">
            <Illustration />
        </ImageWrapper>

        <TextWrapper>
            <Name data-testid="caseStudySummaryName">{featuredLabel}</Name>

            <Heading data-testid="caseStudySummaryTitle">
                <HeadingLink to={slug} linkText={title} />
            </Heading>
            <p data-testid="caseStudySummaryBody">{supporting}</p>
        </TextWrapper>
    </div>
);

export const CaseStudySummary = styled(UnstyledCaseStudySummary)<
    ICaseStudySummaryProps
>`
    position: relative;

    p {
        margin-bottom: 0;
    }

    ${media.m`
        display: flex;
        align-items: flex-start;
        //@ts-ignore
        flex-direction:  ${({ reverse }: ICaseStudySummaryProps) =>
            reverse ? 'row-reverse' : ''};

        ${TextWrapper} {
            flex: 50% 1 1;
        }

        ${ImageWrapper} {
            width: calc(50% - 1.5rem);

            &:before {
                //@ts-ignore
                left:${({ reverse }: ICaseStudySummaryProps) =>
                    reverse ? '0' : '-100vw'};
                //@ts-ignore
                right:${({ reverse }) => (reverse ? '-100vw' : 0)};
            }
        }

        ${TextWrapper} {
            padding-top: 3rem;
            /* padding-left: 3rem; */

            /* additional props not working within media query */
            //@ts-ignore
            padding-left: ${({ reverse }) => (reverse ? 0 : '3rem')};
            //@ts-ignore
            padding-right: ${({ reverse }) => (reverse ? '3rem' : '0')};
        }
    `}

    ${media.l`
        ${TextWrapper} {
                /* additional props not working within media query */
                //@ts-ignore
                padding-left: ${({ reverse }: any) => (reverse ? 0 : '6rem')};
                //@ts-ignore
                padding-right: ${({ reverse }: ICaseStudySummaryProps) =>
                    reverse ? '6rem' : '0'};
        }
        ${ImageWrapper} {
                /* additional props not working within media query */
                //@ts-ignore
                margin-left: ${({ reverse }) => (reverse ? 0 : pullBackWide)};
                //@ts-ignore
                margin-right: ${({ reverse }) =>
                    reverse ? pullBackWide : '0'};

                width: calc(50% - 3rem - ${pullBackWide});
        }
    `}


    ${media.xxl`
        ${TextWrapper} {
            padding-top: 4rem;

            /* additional props not working within media query */
            //@ts-ignore
            padding-left: ${({ reverse }: any) => (reverse ? 0 : '6rem')};
            //@ts-ignore
            padding-right: ${({ reverse }: ICaseStudySummaryProps) =>
                reverse ? '6rem' : '0'};
        }

        ${ImageWrapper} {
            /* additional props not working within media query */
            //@ts-ignore
            margin-left: ${({ reverse }: ICaseStudySummaryProps) =>
                reverse ? 0 : pullBackExtraWide};
            //@ts-ignore
            margin-right: ${({ reverse }: ICaseStudySummaryProps) =>
                reverse ? pullBackExtraWide : '0'};

            width: calc(50% - 3rem - ${pullBackExtraWide});
        }
    `}
`;
