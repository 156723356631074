import React from 'react';
import styled from '../../styled-components';
import { Link, Swell, StylelessLink, Constrain, HeadingLevel1 } from '../ui';
import { ContentSummary } from '../ContentSummary/ContentSummary.component';
import { pixelToRem, media } from '../../utilities';
import { SwellWrapper } from '../SwellWrapper/SwellWrapper.component';

interface IRiptideProps extends wmw.IBaseStyledComponentProps {
    articles: wmw.IContentSummary[];
}

const isOdd = (num: number) => num % 2;

const SummaryWrapper = styled.div`
    color: ${({ theme }) => theme.colors.white.color};
    overflow: auto;

    ${media.l`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ${({ theme }) => theme.spacing.xl};
    `}

    ${Link} {
        color: ${({ theme }) => theme.colors.white.color};

        &:before {
            background: ${({ theme }) => theme.colors.white.color};
        }
    }

    ${StylelessLink} {
        color: ${({ theme }) => theme.colors.white.color};
    }

    ${ContentSummary} {
        margin-bottom: ${({ theme }) => theme.spacing.xl};

        &:last-child {
            margin: 0;
        }

        ${media.l`
            margin-bottom: 0;

            &:nth-child(1), 
            &:nth-child(2) {
                grid-column-end: 3;
                grid-column-start: 1;
            }
        `}
    }

    .summary-image {
        &:before {
            display: none;
        }
    }
`;

export const Riptide: React.SFC<IRiptideProps> = ({ articles }) => (
    <SwellWrapper
        title="The Riptide."
        subtitle="Insights against the flow."
        sectionId="our-insights"
    >
        <SummaryWrapper>
            {articles.map((article, index) => (
                <ContentSummary
                    imageAlign={isOdd(index) ? 'left' : 'right'}
                    {...article}
                    key={index}
                />
            ))}
        </SummaryWrapper>
    </SwellWrapper>
);
