import * as React from 'react';
import { withLayout, LayoutProps } from '../components/Layout';
import ValueProp from '../components/ValueProp/ValueProp.component';
import Masthead from '../components/Masthead/Masthead.component';
import { RiptideContainer } from '../components/Riptide/Riptide.container';
import Helmet from 'react-helmet';
import CallToContact from '../components/CallToContact/CallToContact.component';
import FeaturedCaseStudies from '../components/FeaturedCaseStudies/FeaturedCaseStudies.container';
import { WhoCanWeHelp } from '../components/WhoCanWeHelp/WhoWeCanHelp';
import { WhatWeDo } from '../components/WhatWeDo/WhatWeDo.component';
import homepageSocialImage from '../images/homepage-social-image.jpg';

const homepageMetaTitle =
    'Web & mobile application development | We Make Waves';
const homepageMetaDescription =
    'We partner with startups and forward thinking organisations to build web and mobile applications that deliver impact. A bold, honest and different digital product studio.';

class IndexPage extends React.Component<LayoutProps> {
    public render() {
        return (
            <>
                <Helmet>
                    <meta
                        name="description"
                        content={homepageMetaDescription}
                    />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@wemakewaves" />

                    <meta name="twitter:title" content={homepageMetaTitle} />
                    <meta
                        name="twitter:description"
                        content={homepageMetaDescription}
                    />

                    <meta
                        name="twitter:image"
                        content={`https://wemakewaves.digital${homepageSocialImage}`}
                    />

                    <meta property="og:title" content={homepageMetaTitle} />

                    <meta
                        property="og:description"
                        content={homepageMetaDescription}
                    />
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://wemakewaves.digital/"
                    />
                    <meta
                        property="og:image"
                        content={`https://wemakewaves.digital${homepageSocialImage}`}
                    />
                </Helmet>

                <Masthead />

                <ValueProp />

                <WhatWeDo />

                <WhoCanWeHelp />

                <FeaturedCaseStudies />

                <RiptideContainer />

                <CallToContact />
            </>
        );
    }
}

export default withLayout(IndexPage);
