import React from 'react';
import { FeaturedCaseStudies } from './FeaturedCaseStudies.component';
import {
    VFPIllustration,
    REDIllustration,
    LimpidMarketsIllustration,
    emittoIllustration
} from '../AnimatedIllustrations';

import { StaticQuery, graphql } from 'gatsby';
import { MdxConnection } from '../../generated/graphql-types';

interface FeaturedCaseStudiesProps {
    caseStudies: MdxConnection;
}

function getIllustrationByName(name: string) {
    switch (name) {
        case 'Limpid Markets':
            return LimpidMarketsIllustration;
        case 'Pubal.app SaaS platform':
            return REDIllustration;
        case 'Vote for Policies':
            return VFPIllustration;
        case 'EMITTO app':
            return emittoIllustration;
        default:
            throw new Error(`No animated illustration found for: ${name}`);
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query FeaturedCaseStudyQuery {
                caseStudies: allMdx(
                    filter: {
                        frontmatter: {
                            draft: { ne: true }
                            featured: { eq: true }
                        }
                        fileAbsolutePath: { regex: "/case-studies/" }
                    }
                    sort: { fields: frontmatter___featuredOrder, order: ASC }
                    limit: 4
                ) {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                summary
                                name
                                featuredLabel
                            }
                        }
                    }
                }
            }
        `}
        render={({ caseStudies: caseStudiesRaw }: FeaturedCaseStudiesProps) => {
            const caseStudies = caseStudiesRaw.edges.map(({ node }) => {
                const {
                    title,
                    summary,
                    name,
                    featuredLabel
                } = node.frontmatter;

                const slug = node.fields.slug;

                return {
                    title,
                    supporting: summary,
                    featuredLabel,
                    name,
                    slug,
                    Illustration: getIllustrationByName(name)
                };
            });

            return <FeaturedCaseStudies caseStudies={caseStudies} />;
        }}
    />
);
