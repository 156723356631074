import React from 'react';
import styled from '../../styled-components';
import { CaseStudySummary } from '../CaseStudySummary/CaseStudySummary.component';
import { media } from '../../utilities';
import { Constrain, NavigationAnchor, HeadingLevel1 } from '../ui';
import { PartnerLogos } from '../PartnerLogos/PartnerLogos.component';

export interface IFeaturedCaseStudiesProps
    extends wmw.IBaseStyledComponentProps {
    caseStudies: wmw.ICaseStudySummary[];
}

const Wrapper = styled.div`
    position: relative;
    margin-bottom: 6rem;

    ${media.l`
        margin-bottom: 9rem;
    `}

    & ${CaseStudySummary} {
        margin-bottom: 6rem;

        ${media.m`
            margin-bottom: 3rem;
        `}

        ${media.xl`
            margin-bottom: 6rem;
        `}

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const FeaturedCaseStudies: React.FC<IFeaturedCaseStudiesProps> = ({
    caseStudies
}) => (
    <>
        <Wrapper>
            <NavigationAnchor id="our-work" />

            <Constrain>
                <HeadingLevel1 as="h2">Our wave riders.</HeadingLevel1>

                {caseStudies.map((caseStudy, index) => (
                    <CaseStudySummary
                        {...caseStudy}
                        key={index}
                        reverse={Boolean(index % 2)}
                    />
                ))}

                <PartnerLogos />
            </Constrain>
        </Wrapper>
    </>
);
