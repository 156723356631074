import React from 'react';
import styled from '../../styled-components';

import apple from '../../images/logos/apple.svg';
import britishrowing from '../../images/logos/britishrowing.svg';
import ccs from '../../images/logos/ccs.svg';
import hotwire from '../../images/logos/hotwire.svg';
import limpidmarkets from '../../images/logos/limpidmarkets.svg';
import nhs from '../../images/logos/nhs.svg';
import pubal from '../../images/logos/pubal.svg';
import thomsonlocal from '../../images/logos/thomsonlocal.svg';
import voteforpolicies from '../../images/logos/voteforpolicies.svg';
import caseWestern from '../../images/logos/case-western-reserve-university.png';
import emitto from '../../images/logos/emitto.svg';

import { media, pixelToRem } from '../../utilities';

const UnstyledPartnerLogos: React.FC<wmw.IBaseStyledComponentProps> = ({
    className
}) => (
    <ul className={className}>
        <li>
            <img src={apple} alt="Apple logo" />
        </li>
        <li>
            <img src={caseWestern} alt="Case Western Reserve University logo" />
        </li>
        <li>
            <img src={nhs} alt="NHS logo" />
        </li>
        <li>
            <img src={hotwire} alt="Hotwire global logo" />
        </li>
        <li>
            <img src={britishrowing} alt="British Rowing logo" />
        </li>
        <li>
            <img src={emitto} alt="EMITTO logo" />
        </li>
        <li>
            <img src={ccs} alt="Crown Commercial Service logo" />
        </li>
        <li>
            <img src={limpidmarkets} alt="Limpid Markets logo" />
        </li>
        <li>
            <img src={voteforpolicies} alt="Vote for policies logo" />
        </li>
        <li>
            <img src={pubal} alt="Pubal logo" />
        </li>
    </ul>
);

export const PartnerLogos = styled(UnstyledPartnerLogos)`
    list-style: none;
    margin: 0 -${pixelToRem(15)} 3rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;

    ${media.l`
        margin: 0 -20px 6rem;
    `}

    li {
        flex: 1 0 50%;
        padding: 0 ${pixelToRem(15)};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3.5rem;

        img {
            height: auto;
            width: auto;
            max-height: 100%;
            max-width: 100%;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            flex: 1 0 33%;
        }

        &:first-child {
            flex-basis: 20%;
        }

        ${media.m`
            margin-bottom: 4rem;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                flex: 1 0 25%;
            }

            & {
                flex: 1 0 33%;
            }

            &:nth-last-child(1),
            &:nth-last-child(2) {
                justify-content: center;
            }

            &:first-child {
                flex-basis: 20%;
                justify-content: flex-start;
            }

            &:nth-child(2) {
                flex-basis: 22%;
            }

            &:nth-child(5),
            &:nth-child(7) {
                justify-content: center;
            }
        `}

        ${media.l`
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            & {
                flex-basis: 20%;
                padding: 20px;
                justify-content: center;
            }

            &:first-child {
                flex-basis: 12%;
                justify-content: flex-start;
            }
        `}
    }
`;
