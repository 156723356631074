import React from 'react';
import styled from '../../styled-components';
import {
    Constrain,
    HeadingLevel1,
    LinkArrow,
    LinkText,
    pullbackMarginsSmall
} from '../ui';
import { media, pixelToRem } from '../../utilities';
import arrowSvg from '../../images/arrow.svg';
import { DarkCTA, CTALink } from '../CTAs/DarkCTA';
import earthIllustrationWhite from '../../images/illustration-earth-no-star--white.svg';

const CTAWrapper = styled.div`
    margin: 0 auto;

    ${media.l`
        max-width: 85.6rem;
    `}

    ${media.xl`
        max-width: 105.6rem;
    `}
`;
const Wrapper = styled.div`
    margin-bottom: ${pixelToRem(100)};

    .no-wrap {
        white-space: nowrap;
    }
`;

const WhoWeCanHelpStatement = styled.p<{ open?: boolean }>`
    font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level4.lineHeight};
    font-weight: 500;
    padding: 0.5rem 3rem 0.5rem 2rem;

    ${media.m`
        padding: .5rem .5rem .5rem 2rem;
    `}

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level3.lineHeight};
    `}

    &:before {
        content: '';
        position: absolute;
        width: 1.5rem;
        top: 10px;
        bottom: ${({ open }) => (open ? '0' : '10px')};
        left: 0;
        background-color: ${({ theme, open }) =>
            open
                ? theme.colors.electricBlue.color
                : theme.colors.deepBlue.color};

        transition: background-color 300ms ease;

        ${media.m`
            width: 2rem;
        `}
    }

    &:hover:before {
        background-color: ${({ theme }) => theme.colors.electricBlue.color};
    }

    em {
        font-style: normal;

        @media (any-hover: hover) {
            transition: background 300ms;
            white-space: nowrap;
            background-image: linear-gradient(
                0deg,
                ${({ theme }) => theme.colors.electricBlue.color} 2px,
                hsla(0, 0%, 100%, 0) 0
            );
            background-position: -500px 0px;
            background-repeat: no-repeat;
            transition: background-position 300ms;
        }
    }

    @media (any-hover: hover) {
        &:hover em {
            background-position: 0px 0px;
        }
    }
`;

const StatementWrapper = styled.div<{ open?: boolean }>`
    padding-left: 1rem;
    position: relative;
    margin-bottom: ${pixelToRem(40)};

    ${pullbackMarginsSmall};

    ${media.m`
        margin-left: 0;
        margin-right: 0;
        padding-left: 2rem;
        padding-right: 0;
    `}

    &:before {
        content: '';
        position: absolute;
        width: 1.5rem;
        top: 10px;
        bottom: ${({ open }) => (open ? '0' : '10px')};
        left: 0;
        background-color: ${({ theme, open }) =>
            open
                ? theme.colors.electricBlue.color
                : theme.colors.deepBlue.color};

        transition: background-color 300ms ease;

        ${media.m`
            width: 2rem;
        `}
    }

    &:hover:before {
        background-color: ${({ theme }) => theme.colors.electricBlue.color};
    }
`;

export const WhoCanWeHelp = () => (
    <Wrapper>
        <Constrain>
            <HeadingLevel1>How can we help you?</HeadingLevel1>

            <StatementWrapper>
                <WhoWeCanHelpStatement>
                    <LinkArrow
                        inline={true}
                        color="deepBlue"
                        arrowColor="electricBlue"
                        to="/entrepreneur-with-an-idea-for-innovative-web-or-mobile-app"
                    >
                        I'm an entrepreneur with <em>a new idea.</em>
                    </LinkArrow>
                </WhoWeCanHelpStatement>
            </StatementWrapper>

            <StatementWrapper>
                <WhoWeCanHelpStatement>
                    <LinkArrow
                        inline={true}
                        color="deepBlue"
                        arrowColor="electricBlue"
                        to="/helping-organisations-improve-an-existing-process"
                    >
                        We're an organisation that wants to build software to{' '}
                        <em>improve an existing process</em>
                    </LinkArrow>
                </WhoWeCanHelpStatement>
            </StatementWrapper>

            <StatementWrapper>
                <WhoWeCanHelpStatement>
                    <LinkArrow
                        inline={true}
                        color="deepBlue"
                        arrowColor="electricBlue"
                        to="/helping-organisations-fill-a-gap-in-a-team"
                    >
                        We're an organisation needing to <em>fill a gap in our team</em> with proven&nbsp;expertise
                    </LinkArrow>
                </WhoWeCanHelpStatement>
            </StatementWrapper>

            <CTAWrapper>
                <DarkCTA>
                    <div>
                        <h4>Making an ethical impact</h4>
                        <p>
                            Do you have an idea for a software product in the
                            ethical space?
                            <CTALink>
                                <LinkArrow
                                    to="/making-an-ethical-impact"
                                    arrowColor="green"
                                >
                                    <LinkText underlineColor="green">
                                        Find out how we can help you make an
                                        ethical impact.
                                    </LinkText>
                                </LinkArrow>
                            </CTALink>
                        </p>
                    </div>

                    <img
                        src={earthIllustrationWhite}
                        alt="Making an ethical impact"
                    />
                </DarkCTA>
            </CTAWrapper>
        </Constrain>
    </Wrapper>
);
