import React from 'react';
import styled from '../../styled-components';
import {
    Constrain,
    pullbackMarginsSmall,
    pullbackMarginsWide,
    pullbackMarginsExtraWide,
    ListStyles
} from '../ui';
import { media } from '../../utilities';
import swellBg from '../../images/swell-background.svg';
import theme from '../../theme';

interface IWhatWeDoProps extends wmw.IBaseStyledComponentProps {}

const ServicesHeading = styled.h3`
    font-family: ${({ theme }) => theme.fontNew.secondary.type};
    font-size: ${({ theme }) => theme.fontNew.secondary.size.level4.size};
    line-height: ${({ theme }) => theme.fontNew.primary.size.level3.lineHeight};
    font-weight: normal;
    margin-bottom: 2rem;

    ${media.xl`
        font-size: ${({ theme }) => theme.fontNew.secondary.size.level3.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level2.lineHeight};
    `}
`;

const ServiceBlock = styled.div`
    padding: 3rem 2rem;
    background-color: white;
    position: relative;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &:before {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        right: -1px;
        height: calc(50% - 49px);
        z-index: -1;
        background-color: ${theme.colors.electricBlue.color};
    }

    &:after {
        content: '';
        position: absolute;
        left: -4px;
        height: calc(50% - 46px);
        right: -4px;
        bottom: -4px;
        z-index: -1;
        background-color: ${theme.colors.electricBlue.color};
    }

    ${media.m`
        max-width: 50rem;
        margin: 0 auto;
        ${ServicesHeading} {
            white-space: nowrap;
        }
    `}

    ${media.l`
        margin: 0;
        max-width: none;
    `}

    ${media.xl`
        padding: 3rem;
    `}
`;

const Services = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    ${media.l`
        flex-direction: row;
        justify-content: center;
        margin: 0 -1rem;
    `}
`;

const ServicesItem = styled.li`
    background: url(${swellBg});
    background-repeat: repeat-x;
    background-position: center;
    margin-bottom: 4rem;
    padding: 0 3rem;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    ${media.l`
            display: flex;
            align-items: center;
            background: none;
            margin-bottom: 0;
            max-width: none;
            padding: 0 1rem;

            &:nth-child(2) {
                order: 1;
                flex: .9;
            }

            &:nth-child(1) {
                order: 2;
                margin-top: -4rem;
                margin-bottom: -4rem;
                flex: 1;
                
                > div {
                    align-self: stretch;
                }
            }

            &:nth-child(3) {
                order: 3;
                flex: .9;
            }
        `}

    ${media.xl`
            padding: 0 2rem;
    `}

    strong {
        font-weight: 500;
    }

    ul {
        ${ListStyles}

        margin-bottom: 0 !important;

        & > li:before {
            color: ${({ theme }) => theme.colors.electricBlue.color};
        }
    }

    li {
        list-style: none;
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

class UnstyledWhatWeDo extends React.Component<IWhatWeDoProps> {
    public render() {
        const { className } = this.props;

        return (
            <div className={className}>
                <Constrain>
                    <div className="services-container">
                        <Services>
                            <ServicesItem>
                                <ServiceBlock>
                                    <ServicesHeading>
                                        Lean strategy
                                    </ServicesHeading>
                                    <p>
                                        <strong>Let's work together to:</strong>
                                    </p>
                                    <ul>
                                        <li>
                                            Outline what you need to be
                                            successful and what may get in your
                                            way.
                                        </li>
                                        <li>
                                            Ensure your idea is viable and
                                            uncover new opportunities through
                                            user research.
                                        </li>
                                        <li>
                                            Maximise your budget by identifying
                                            the core value of your product and
                                            the shortest path to delivering it.
                                        </li>
                                        <li>
                                            Continuously improve your product by
                                            getting it into the hands of real
                                            users quick and often.
                                        </li>
                                    </ul>
                                </ServiceBlock>
                            </ServicesItem>

                            <ServicesItem>
                                <ServiceBlock>
                                    <ServicesHeading>Design</ServicesHeading>

                                    <p>
                                        <strong>
                                            Here's how you'll get a stunning
                                            user experience:
                                        </strong>
                                    </p>

                                    <ul>
                                        <li>
                                            Journey maps to visualise how your
                                            users achieve their goals within the
                                            product.
                                        </li>

                                        <li>
                                            Collaborative sketching workshops to
                                            generate better ideas, faster.
                                        </li>

                                        <li>
                                            Testing prototypes with your users
                                            to validate ideas at the earliest
                                            stage.
                                        </li>
                                    </ul>
                                </ServiceBlock>
                            </ServicesItem>

                            <ServicesItem>
                                <ServiceBlock>
                                    <ServicesHeading>
                                        Development
                                    </ServicesHeading>
                                    <p>
                                        <strong>
                                            We develop to the highest quality,
                                            expect the following:
                                        </strong>
                                    </p>

                                    <ul>
                                        <li>
                                            Choosing technologies that
                                            complement your product and its
                                            future needs.
                                        </li>
                                        <li>
                                            Continuous integration to rapidly
                                            deliver product features to your
                                            users.
                                        </li>
                                        <li>
                                            BDD & TDD techniques to ensure
                                            quality and minimise the cost of
                                            change.
                                        </li>
                                    </ul>
                                </ServiceBlock>
                            </ServicesItem>
                        </Services>
                    </div>
                </Constrain>
            </div>
        );
    }
}

export const WhatWeDo = styled(UnstyledWhatWeDo)`
    margin-bottom: 6rem;

    ${media.l`
        padding: 4rem 0 4rem 0;
        background: url(${swellBg});
        background-repeat: repeat-x;
        background-position: center;
        display: flex;
        justify-content: center;
        margin-bottom: 9rem;
    `}

    .services-container {
        ${pullbackMarginsSmall};

        ${media.m`
            ${pullbackMarginsWide};
        `}

        ${media.l`
            ${pullbackMarginsWide};
        `}

        ${media.xxl`
            ${pullbackMarginsExtraWide};
        `}
    }
`;
