import * as React from 'react';
import styled, { keyframes } from '../../styled-components';
import UnstyledTooltip from 'react-tooltip-lite';
import { media } from '../../utilities';
import burst from '../../images/burst.svg';

const Tooltip = styled(UnstyledTooltip)`
    .react-tooltip-lite {
        background: white;
        border: solid 1px ${({ theme }) => theme.colors.deepBlue.color};
        box-shadow: 0px 0px 4px rgba(0, 61, 76, 0.4);
    }
`;

import { Constrain, NavigationAnchor, pullBackWide } from '../ui';
class UnstyledValueProp extends React.Component<wmw.IBaseStyledComponentProps> {
    public render() {
        return (
            <div className={this.props.className}>
                <NavigationAnchor id="what-we-do" />

                <Constrain>
                    <h1>
                        <div className="intro">
                            Most software endeavours fail to reach their full
                            potential. It doesn't have to be this way.
                        </div>
                    </h1>

                    <p>
                        Failure rates in our industry are surprisingly high. 86%
                        of IT projects either partially or completely fail
                        <Tooltip
                            content="The Standish Group CHAOS Report"
                            tagName="sup"
                            padding="5px 7px"
                            arrow={false}
                        >
                            1
                        </Tooltip>{' '}
                        and a whopping 9 out of 10 startups bite the dust
                        <Tooltip
                            content="Startup Genome Global Startup Ecosystem Report"
                            tagName="sup"
                            padding="5px 10px"
                            arrow={false}
                        >
                            2
                        </Tooltip>
                        .
                    </p>

                    <p>
                        Our technology partnership is a proven approach to
                        successful web and mobile application development,
                        ensuring we solve the right problems for the right
                        people, creating software that’s easy to adapt, whilst
                        not blowing budgets.
                    </p>

                    <div className="sign-off">
                        Why make ripples when we can <em>make waves</em>
                    </div>
                </Constrain>
            </div>
        );
    }
}

export default styled(UnstyledValueProp)`
    position: relative;
    padding: 6rem 0;

    ${media.l`
        padding: 6rem 0
    `}

    ${media.xl`
        padding: 9rem 0
    `}

    h1 {
        margin-bottom: 0;
        font-weight: 500;
    }

    p {
        sup {
            text-decoration: underline;
            cursor: pointer;
            font-size: 65%;
            top: -0.8em;

            &:hover {
                text-decoration-color: ${({ theme }) =>
                    theme.colors.electricBlue.color};
            }
        }

       ${media.l`
            margin-left: ${pullBackWide};
            font-size: ${({ theme }) => theme.fontNew.primary.size.level4.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
        `}
    }

    .intro {
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
        line-height: ${({ theme }) =>
            theme.fontNew.primary.size.level5.lineHeight};
        margin-bottom: ${({ theme }) => theme.spacing.l};

        ${media.m`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
        `}

        ${media.l`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level1.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level1.lineHeight};
            margin-left: ${pullBackWide}
        `}

        /* ${media.xl`
            max-width: 1020px;
        `} */
    }

    .sign-off {
        font-weight: 500;
        font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
        line-height: 3.4rem;

        ${media.m`
            text-align:right;
            font-size: ${({ theme }) => theme.fontNew.primary.size.level5.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level4.lineHeight};
        `}

        ${media.l`
            font-size: ${({ theme }) => theme.fontNew.primary.size.level3.size};
            line-height: ${({ theme }) =>
                theme.fontNew.primary.size.level3.lineHeight};
            margin-right: ${pullBackWide};
            margin-top: 3rem;
        `}
    }

    em {
        font-weight: normal;
        font-family: ${({ theme }) => theme.fontNew.secondary.type};
        font-size: 3.2rem;
        display: inline-block;
        border-bottom: 2px solid
            ${({ theme }) => theme.colors.electricBlue.color};
        line-height: 30px;
        white-space: nowrap;

        ${media.m`
            border-bottom: none;
            position: relative;
            background: url(${burst}) no-repeat center center;
            background-size: contain;
            line-height: 1;
            font-size: 4.2rem;
            padding: 2.5rem;
            margin: -2.5rem;
        `}

        ${media.l`
            /* font-size: 6.2rem; */
            display: inline-block;
            padding: 4rem;
            margin: -4rem;
        `};
    }
`;
